import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import SectionStandard from '../../components/section-standard';
import Products from '../../components/products';
import HeroBackgroundImage from '../../components/hero-background-image';
import Image from '@components/image/image';
// services
import { trackEvent } from '../../services/tracking/tracking';
// styles
import styles from './womens-health.module.scss';

const WomensHealthPage = () => {
  const content = useStaticQuery(graphql`
    query WomensHealth {
      directusWomensHealth {
        page_title
        page_description
        hero_title
        hero_description
        hero_button_label
        hero_image_desktop {
          title
          filename_disk
        }
        hero_image_tablet {
          title
          filename_disk
        }
        hero_image_mobile {
          title
          filename_disk
        }
        section_1_title
        section_1_description
        section_1_stages {
          text
          image_filename_disk
          link_to
        }
        section_2_title
        section_2_description
        section_2_products {
          id
          title
          subtitle
          description
          price
          image_filename_disk
          button_label
        }
        section_3_title
        section_3_subtitle
        section_3_button_label
        section_3_image {
          filename_disk
        }
      }
    }
  `);

  const { directusWomensHealth } = content;
  const [activeProduct, setActiveProduct] = useState();

  const onHeroButtonClick = () => {
    navigate('#stages');
  };

  // tracking
  useEffect(() => {
    trackEvent('Product List Viewed', {
      list_id: "Women's Health",
      category: "Women's Health",
    });
  }, []);

  return (
    <Layout>
      <SEO title={directusWomensHealth.page_title} description={directusWomensHealth.page_description} />

      <HeroBackgroundImage
        title={directusWomensHealth.hero_title}
        description={directusWomensHealth.hero_description}
        buttonLabel={directusWomensHealth.hero_button_label}
        buttonAction={onHeroButtonClick}
        desktopImage={directusWomensHealth.hero_image_desktop}
        tabletImage={directusWomensHealth.hero_image_tablet}
        mobileImage={directusWomensHealth.hero_image_mobile}
      />

      <section className={styles.sectionPrimaryLighter} id="stages">
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{directusWomensHealth.section_1_title}</h2>
              <hr />
              <p className={styles.subheading}>{directusWomensHealth.section_1_description}</p>
              <div className={styles.stages}>
                {directusWomensHealth.section_1_stages.map((stage: any, index: number) => (
                  <a
                    key={index}
                    className={styles.buttonStage}
                    href={`#${stage.link_to}`}
                    onClick={() => setActiveProduct(stage.link_to)}
                  >
                    <Image filename={stage.image_filename_disk} width={30} />
                    {stage.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentProducts}>
              <h2>{directusWomensHealth.section_2_title}</h2>
              <hr />
              <p className={styles.subheading}>{directusWomensHealth.section_2_description}</p>

              <Products
                products={directusWomensHealth.section_2_products}
                activeProduct={activeProduct}
                itemsPerRow={2}
              />
            </div>
          </div>
        </div>
      </section>

      <SectionStandard
        title={directusWomensHealth.section_3_title}
        description={directusWomensHealth.section_3_subtitle}
        background="PrimaryLighter"
        buttonLabel={directusWomensHealth.section_3_button_label}
        buttonAction={() => navigate('/consultation')}
        image={directusWomensHealth.section_3_image}
      />
    </Layout>
  );
};

export default WomensHealthPage;
